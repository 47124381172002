import { HashRouter } from "react-router-dom";
import ZSAppWrapper from './pages/app';
import './App.css';

export default function App() {
  return (
      <HashRouter>
        {/* Router路由映射 */}
        <ZSAppWrapper />
      </HashRouter>
  );
}