import React from 'react'
import { Redirect } from 'react-router-dom'

const MainPage = React.lazy(() => import('@/pages'))

const routes = [
  { path: '/', exact: true, render: () => <Redirect to="/zhisuo" /> },
  { path: '/zhisuo/:channel', exact: false, component: MainPage },
  { render: () => <Redirect to="/zhisuo" /> }
]

export default routes